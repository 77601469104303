@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --theme-color: #000f3c;

    --bg-color-primary: #ebecf0;
    --bg-color-secondary: #000f3c;

    --text-primary: #000f3c;
    --text-secondary: #ffffff;
  }
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip .tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #fff;

  color: #000; /* Text color */
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 15%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.custom-tooltip-btn .tooltip-text-btn {
  visibility: hidden;
  width: 150px;
  background-color: #fff;

  color: #000; /* Text color */
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 110%;
  right: -5%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.custom-tooltip-btn:hover .tooltip-text-btn {
  visibility: visible;
  opacity: 1;
}

.custom-tooltip-faq-btn .tooltip-text-faq-btn {
  visibility: hidden;
  width: 150px;
  background-color: #fff;

  color: #000; /* Text color */
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 15%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}
.custom-tooltip-faq-btn:hover .tooltip-text-faq-btn {
  visibility: visible;
  opacity: 1;
}

.scrollable-container::-webkit-scrollbar {
  display: none !important;
}

.scrollable-container {
  scrollbar-width: none !important;
}
