.topWallEnterActive {
  transform: translateY(-100%);
}

.topWallEnterDone {
  transform: translateY(0);
  transition: transform 200ms linear;
}

.topWallExitActive {
  transform: translateY(-100%);
  transition: transform 200ms linear;
}

.topWallExitDone {
  transform: translateY(-100%);
}

.botWallEnterActive {
  transform: translateY(100%);
}

.botWallEnterDone {
  transform: translateY(0);
  transition: transform 200ms linear;
}

.botWallExitActive {
  transform: translateY(100%);
  transition: transform 200ms linear;
}

.botWallExitDone {
  transform: translateY(100%);
}

.loaderEnterActive {
  opacity: 0;
}

.loaderWallEnterDone {
  opacity: 1;
  transition: all 200ms;
}

.loaderWallExitActive {
  opacity: 1;
}

.loaderWallExitDone {
  opacity: 0;
  transition: all 200ms;
}
