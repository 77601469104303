.container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 30;
  top: 0;
}

.loadContainer {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loadContainer div {
  display: inline-block;
  width: 16px;
  left: 8px;
  height: 32px;
  position: absolute;
  background: var("--color");
  animation: load 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loadContainer div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loadContainer div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loadContainer div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}

@keyframes load {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
