.MyClassEnterActive {
  opacity: 0;
  transform: translateY(200px);
}

.MyClassEnterDone {
  opacity: 1;
  transform: translateY(0px);
  transition: all 500ms;
}

.MyClassExit {
  opacity: 0;
  transform: translateY(200px);
  transition: all 500ms;
}

/* .MyClassExitActive{
      opacity: 0;
      transform: translateY(-200px);
  } */

.FormModalEnterActive {
  opacity: 0;
  transform: translateY(-300px);
}

.FormModalEnterDone {
  opacity: 1;
  transform: translateY(0px);
  transition: all 500ms;
}

.FormModalExitActive {
  opacity: 0;
  transform: translateY(-300px);
  transition: all 500ms;
}

.FormModalExitDone {
  opacity: 0;
  transform: translateY(-300px);
}

.img_label {
  position: relative;
  display: block;
  width: 30%;
  height: 45px;
  line-height: 50px;
  text-align: center;
  background: #000f3c;
  color: #fff;
  font-size: 15px;
  font-family: ”Open Sans”, sans-serif;
  text-transform: Uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.img_label::before {
  content: "UPLOAD";
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 45px;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  opacity: 0;
}

.img_label:hover::before {
  opacity: 1;
  color: #000f3c;
  width: 100%;
  transition: all 300ms ease;
  border: 1px solid #000f3c;
}

.img_label_nova {
  position: relative;
  display: block;
  width: 30%;
  height: 45px;
  line-height: 50px;
  text-align: center;
  background: #000;
  color: #e1ff3c;
  font-size: 15px;
  font-family: ”Open Sans”, sans-serif;
  text-transform: Uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.img_label_nova::before {
  content: "UPLOAD";
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 45px;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  opacity: 0;
  color: #000;
}

.img_label_nova:hover::before {
  opacity: 1;
  color: #000;
  width: 100%;
  transition: all 300ms ease;
  border: 1px solid #000;
}

.img_input {
  display: none;
  content: Upload Banner;
}

.labelStyle {
  font-weight: bold;
  color: #000f3c;
}
