.table_style {
  min-width: 100%;
}

.table_head_row {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.table_head_column {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  text-align: left;
  text-transform: capitalize;
  text-align: center;
}

/* .bodytable- {
    border-top-width: 2px;
    border-bottom-width: 0px;
} */

.table_body > tr {
  background-color: rgb(255, 255, 255);
  border-top-width: 1px;
  border-bottom-width: 0px;
}

.table_body > tr > td {
  text-align: center;
}

.pagination {
  width: max-content;
  height: max-content;
  margin-top: 5px;
  background-color: #fff;
}

.active_btn {
  background-color: #000f3c;
  color: #fff;
}
.active_btn_nova {
  background-color: #000;
  color: #000f3c;
}

/* ::placeholder{
    color: #3a82ca;
}

:-ms-input-placeholder{
    color: #3a82ca;
}

::-ms-input-placeholder{
    color: #3a82ca;
} */
