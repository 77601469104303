.alertEnterActive {
    opacity: 0;
    scale: 0;
}

.alertEnterDone {
    opacity: 1;
    scale: 1;
    transition: all 200ms ease;
}

.alertExitActive {
    opacity: 0;
    scale: 0;
    transition: all 200ms ease;
}

.alertExitDone {
    opacity: 0;
    scale: 0;
}